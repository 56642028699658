@charset "UTF-8";

@import "../mixin";

.gl_nav {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  background: url('/common/images/gl-nav-bg-01.png') no-repeat;
  background-size: cover;
  z-index: 10;
  @include sp {
    position: absolute;
    left: auto;
    bottom: auto;
    right: 0;
    top: 0;
    background: none;
  }
  &--inner {
    display: flex;
    font-weight: normal;
    letter-spacing: 1px;
    position: relative;
    @include sp {
      position: fixed !important;
      left: 0;
      width: 100vw;
      height: auto;
      flex-wrap: wrap;
      background-color: #fff;
      bottom: -300px;
      transition: 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
      &.is_show {
        display: flex;
        bottom: 60px;
      }
    }
  }
  &--link {
    height: 72px;
    color: #fff;
    display: flex;
    align-content: center;
    align-items: center;
    font-size: 16px;
    padding: 0 28px;
    position: relative;
    box-sizing: border-box;
    transition: padding 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    cursor: pointer;
    &:before {
      content: "";
      display: block;
      width: 1px;
      background-color: #fff;
      position: absolute;
      left: calc(50% - 1px);
      bottom: 0;
      height: 0;
      opacity: 0;
      transition: height 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    &:hover {
      padding-bottom: 25px;
      &:before {
        opacity: 1;
        height: 30px;
      }
    }
    @include sp {
      width: 100%;
      color: $colorBlue;
      text-align: center;
      justify-content: center;
      height: 50px;
      border-bottom: 1px solid #f1f1f1;
      &:hover {
        padding-bottom: 0;
        &:before {
          height: 1px;
        }
      }
      &:before {
        content: '';
        opacity: 1;
        display: block;
        position: absolute;
        left: calc(100% - 70px);
        top: 50%;
        width: 40px;
        height: 1px;
        background-color: $colorBlue;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 32px;
        top: calc(50% - 7.5px);
        border-left: 1px solid $colorBlue;
        border-bottom: 1px solid $colorBlue;
        transform: rotate(-135deg);
        height: 15px;
        width: 15px;
        transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      }
    }
    &.is_current {
      padding-bottom: 25px;
      &:before {
        opacity: 1;
        height: 30px;
      }
      @include sp {
        padding-bottom: 0;
        background-color: $colorBlue;
        color: #fff;
        &:before {
          height: 1px;
        }
      }
    }
  }
  &--contact_link {
    background-color: $colorBlue;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-content: center;
    align-items: center;
    width: 210px;
    text-align: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    height: 72px;
    padding: 0 50px 0 0;
    box-sizing: border-box;
    transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    letter-spacing: 2px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      right: 29px;
      top: 50%;
      width: 40px;
      height: 1px;
      background-color: #fff;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 32px;
      top: calc(50% - 10px);
      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      transform: rotate(-135deg);
      height: 20px;
      width: 20px;
      transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    &:hover {
      background-color: darken($colorBlue, 10%);
    }
    @include sp {
      position: fixed;
      left: 0;
      bottom: 0;
      top: auto;
      width: 100%;
      height: 60px;
      padding-right: 0;
      font-weight: bold;
      display: none;
    }
    &-sp {
      display: none;
      @include sp {
        display: flex;
        width: calc(100% - 60px);
      }
    }
  }
  &--menu_btn {
    display: none;
    @include sp {
      display: block;
      position: fixed;
      right: 0;
      overflow: hidden;
      bottom: 0;
      background: url('/common/images/gl-nav-bg-01.png') no-repeat;
      background-size: cover;
      width: 60px;
      height: 60px;
      z-index: 11;
      cursor: pointer;
      &.is_show {
        .gl_nav {
          &--menu_btn_border {
            &:nth-child(1) {
              top: 30px;
              transform: rotate(45deg);
              width: 30px;
              left: 17px;
            }
            &:nth-child(2) {
              left: 90px;
            }
            &:nth-child(3) {
              top: 30px;
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
  }
  &--menu_btn_border {
    display: block;
    height: 3px;
    position: absolute;
    background-color: #fff;
    transition: 0.2s cubic-bezier(0.86, 0, 0.07, 1);
    &:nth-child(1) {
      width: 21px;
      left: 25px;
      top: 19px;
    }
    &:nth-child(2) {
      top: 29px;
      left: 32px;
      width: 15px;
    }
    &:nth-child(3) {
      top: 41px;
      left: 17px;
      width: 30px;
    }
  }
}
