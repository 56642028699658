@charset "UTF-8";

@import "mixin";
@import "common/gl-header";
@import "common/gl-footer";

.cmn-home_page {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url('/images/top-home-page-bg.png');
  background-size: 100vw 100vh;
  transition: 1s cubic-bezier(0.86, 0, 0.07, 1);
  z-index: 11;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  &.is_hidden {
    transition-delay: 1.8s;
    width: 0;
    .cmn-home_page {
      &--headline {
        transform: scale(1.1,1.1);
        transition-delay: 1s;
        opacity: 0;
      }
      &--img_box {
        transform: scale(1.1,1.1);
        transition-delay: 1.2s;
        opacity: 0;
      }
    }
  }
  &--headline {
    color: $colorBlue;
    font-size: 32px;
    text-align: center;
    font-weight: bold;
    width: 100%;
    letter-spacing: 10px;
    margin: 0 0 30px 0;
    transition: 1s cubic-bezier(0.86, 0, 0.07, 1);
    opacity: 1;
    @include sp {
      font-size: 24px;
      margin-bottom: 20px;
    }
    .is_sp {
      display: none;
      @include sp {
        display: block;
      }
    }
  }
  &--img_box {
    transition: 1s cubic-bezier(0.86, 0, 0.07, 1);
    opacity: 1;
  }
  &--img {
  }
  &--img-logo {
    @include sp {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
    }
  }
  &--img-txt {
    @include sp {
      width: 60%;
      display: block;
      margin: -20px auto 0;
    }
  }
}

.cmn-section {
  position: relative;
  &--loadBg{
    position: fixed;
    left: 0;
    top: 0;
    width: 0;
    height: 100vh;
    z-index: 5;
    &-first {
      background-color: $colorBlue;
      opacity: 0.2;
      //transition: 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: 0.4s;
      &.is_show {
        width: 100vw;
        transition-delay: 0s;
      }
      &.is_hidden {
        transition-delay: 0.4s;
        left: 100vw;
      }
      &.is_return {
        transition: 0s;
        left: 0;
        width: 0;
      }
    }
    &-second {
      background-color: #fff;
      //transition: 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: 0.4s;
      &.is_show {
        width: 100vw;
        transition-delay: 0.2s;
      }
      &.is_hidden {
        transition-delay: 0s;
        left: 100vw;
      }
      &.is_return {
        transition: 0s;
        left: 0;
        width: 0;
      }
    }
  }
  &--sub_bg {
    position: absolute;
    left: -440px;
    top: 0;
    width: 405px;
    height: 100%;
    display: block;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: block;
      background-color: #fff;
      transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    @include sp {
      left: -20px;
      top: 190px;
      width: 100vw;
      height: 180px;
    }
    &.is_show {
      &:before {
        height: 0;
      }
    }
    &-interior {
      background: url('/interior/images/cmn-section-bg.png');
    }
    &-exterior {
      background: url('/exterior/images/cmn-section-bg.png');
    }
    &-about {
      background: url('/about/images/cmn-section-bg.png');
      @include sp {
        display: none;
      }
    }
    &-reform {
      background: url('/reform/images/cmn-section-bg.png');
    }
    &-sign {
      background: url('/sign/images/cmn-section-bg.png');
    }
    &-contact {
      background: url('/contact/images/cmn-section-bg.png');
      @include sp {
        display: none;
      }
    }
  }
  &--contents {
    width: 600px;
    position: relative;
    z-index: 2;
    min-height: calc(100vh - 190px);
    @include sp {
      width: 100%;
    }
  }
  &--contents_main {
    position: relative;
    display: block;
    padding: 65px 10px 64px;
    margin: 0 0 30px 0;
    @include sp {
      overflow: hidden;
      padding: 30px 23px 40px;
      margin-bottom: 80px;
    }
    &-top {
      @include sp {
        margin-bottom: 20px;
      }
    }
  }
  &--contents_main_bg {
    position: absolute;
    right: 70px;
    top: 0;
    height: 100%;
    width: 970px;
    box-shadow: 0 0 50px rgba(0,0,0,0.2);
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      transition: 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    @include sp {
      right: 0;
    }
    &.is_show {
      &:before {
        width: 0;
      }
    }
    &-top {
      background: url('/images/cmn-section-contents-main-bg-01.png') no-repeat right 0;
    }
    &-interior {
      background: url('/interior/images/cmn-section-contents-main-bg-01.png') no-repeat right 0;
    }
    &-exterior {
      background: url('/exterior/images/cmn-section-contents-main-bg-01.png') no-repeat right 0;
    }
    &-reform {
      background: url('/reform/images/cmn-section-contents-main-bg-01.png') no-repeat right 0;
    }
    &-sign {
      background: url('/sign/images/cmn-section-contents-main-bg-01.png') no-repeat right 0;
    }
  }
  &--contents_main_headline {
    color: #fff;
    line-height: 1.9;
    position: relative;
    letter-spacing: 11px;
    text-shadow: 0 0 20px rgba(0,0,0,0.3);
    max-width: 520px;
    padding: 0 57px 0 0;
    font-weight: bold;
    box-sizing: border-box;
    font-size: 30px;
    margin: 0 0 25px 0;
    padding-right: 0;
    @include sp {
      font-size: 20px;
      padding-right: 0;
      letter-spacing: 0.2em;
      text-align: center;
    }
    &-top {
      margin: 30px 0;
      @include sp {
        margin: 0;
        left: 7px;
      }
    }
  }
  &--contents_main_description {
    color: #333;
    position: relative;
    max-width: 520px;
    font-size: 14px;
    padding: 0 57px 0 0;
    box-sizing: border-box;
    @include sp {
      padding-right: 0;
    }
    &-white {
      color: #fff;
    }
  }
  &--icatch {
    position: absolute;
    left: 545px;
    top: 0;
    width: 500px;
    height: 500px;
    z-index: 10;
    transition: opacity 0.3s;
    @include sp {
      position: static;
      width: 100%;
      height: 100%;
    }
    &.is_hidden {
      opacity: 0;
    }
    canvas {
      width: 500px !important;
      height: 500px !important;
      @include sp {
        width: 320px !important;
        height: 320px !important;
        margin: 0 auto;
        display: block;
      }
    }
  }
  &--block {
    max-width: 520px;
    padding: 0 15px 50px 15px;
    box-sizing: border-box;
    &:last-child {
      margin-bottom: 0;
    }
    @include sp {
      padding-left: 10px;
      padding-right: 10px;
    }
    &-padding_top {
      padding-top: 40px;
    }
    &-form {
      padding-top: 40px;
      max-width: 100%;
    }
    iframe {
      @include sp {
        width: 100%;
      }
    }
  }
  &--description {
    font-size: 14px;
  }
  &--block_headline {
    font-size: 20px;
    position: relative;
    margin: 0 0 30px 0;
    letter-spacing: 2px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 35px;
      height: 1px;
      background-color: $colorBlue;
      left: -50px;
      top: 50%;
    }
    @include sp {
      text-align: center;
      padding-bottom: 20px;
      &:before {
        left: calc(50% - 12.5px);
        top: auto;
        bottom: 0;
      }
    }
    &-blue {
      color: $colorBlue;
    }
    &-green {
      color: #1b8e76;
      &:before {
        background-color: #1b8e76;
      }
    }
  }
  &--flex_box {
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
  }
  &--block_list {
    font-size: 16px;
    &:nth-child(2) {
      margin-left: 50px;
    }
    @include sp {
      width: 100%;
      &:nth-child(2) {
        margin-left: 0;
      }
    }
  }
  &--block_list_item {
    line-height: 1.8;
    font-size: 14px;
    &:before {
      content: '□';
      display: inline-block;
      margin: 0 5px 0 0;
    }
  }
  &--img_box {
    overflow: hidden;
    border-radius: 3px;
    width: 230px;
    margin-bottom: 15px;
    margin-right: 15px;
    @include sp {
      margin-right: 15px;
    }
  }
  &--note {
    font-size: 12px;
  }
  &--img_box_img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 0 2px 0;
  }
  &--img_box_txt {
    font-size: 14px;
		line-height: 1.4;
  }
  &--form_headline {
    font-size: 32px;
    color: $colorBlue;
    display: block;
    margin: 0 0 30px 0;
    @include sp {
      font-size: 20px;
      margin-bottom: 15px;
    }
  }
  &--form_txt {
    font-size: 14px;
    line-height: 1.5;
  }
}

.cmn-section_sub_list {
  font-size: 14px;
  margin: 0 0 5px 0;
  padding: 0 0 0 1em;
  &--item {
    line-height: 1.8;
    &:before {
      content: '-';
      display: inline-block;
      margin: 0 5px 0 0;
    }
  }
}

.cmn-section_flow_box {
  width: 100%;
  display: flex;
  font-size: 14px;
  align-content: center;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
  margin: 0 0 15px 0;
  min-height: 85px;
  &:last-child {
    margin-bottom: 0;
  }
  @include sp {
    flex-wrap: wrap;
    position: relative;
    padding-left: 60px;
  }
  &--step {
    width: 60px;
    height: 100%;
    text-align: center;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
    color: #fff;
    @include sp {
      position: absolute;
      left: 0;
      top: 0;
    }
    &-step1 {
      background-color: #00be80;
    }
    &-step2 {
      background-color: #005d99;
    }
    &-step3 {
      background-color: #005d99;
    }
    &-step4 {
      background-color: #005d99;
    }
    &-step5 {
      background-color: #1b3768;
    }
  }
  &--headline {
    width: 135px;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
    font-weight: normal;
    position: relative;
    &:after {
      content: '';
      height: 60px;
      width: 1px;
      background-color: #dedede;
      position: absolute;
      right: 0;
      top: calc(50% - 30px);
    }
    @include sp {
      width: 100%;
      height: auto;
      padding: 10px 0;
      br {
        display: none;
      }
      &:after {
        width: 100%;
        top: auto;
        bottom: 0;
        height: 1px;
      }
    }
  }
  &--description {
    flex: 1;
    box-sizing: border-box;
    padding: 10px 15px;
    line-height: 1.5;
    .is_sp {
      @include sp {
        display: none;
      }
    }
  }
  &--link {
    display: block;
    color: $colorBlue;
    cursor: pointer;
    &:before {
      content: '>';
      margin: 0 3px 0 0;
      transform: scale(0.5,1);
      display: inline-block;
    }
  }
}

.cmn-section_info_block {
  width: 100%;
  border-top: 1px dotted #1b3768;
  padding: 10px 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 14px;
  &:last-child {
    border-bottom: 1px dotted #1b3768;
  }
  @include sp {
    padding-left: 0;
    padding-right: 0;
  }
  &--headline {
    width: 135px;
    @include sp {
      width: 85px;
    }
  }
  &--description {
    flex: 1;
  }
}

.cmn-loading_img {
  position: fixed;
  left: calc(50% - 100px);
  top: calc(100% + 20px);
  transition: opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  z-index: 9;
  &.is_show {
    top: calc(50% - 150px);
    opacity: 1;
  }
  &.is_hidden {
    opacity: 0;
  }
}


/**
　　reCaptureを非表示にする
 */
.grecaptcha-badge {
  opacity: 0 !important;
}