@charset "UTF-8";

@import "../mixin";

.gl_header {
  height: 125px;
  background: url('/common/images/gl-header-bg.png') no-repeat;
  background-size: cover;
  position: relative;
  z-index: 10;
  @include sp {
    height: 130px;
  }
  &--inner {
    display: flex;
    align-content: center;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  &--logo_link {
    display: block;
    transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    position: relative;
    top: 28px;
    left: 1px;
    z-index: 1;
    cursor: pointer;
    &:hover {
      opacity: 0.3;
    }
    @include sp {
      top: 8px;
    }
  }
  &--logo_link_img {
  }
  &--page_title {
    color: #5a75a5;
    font-size: 135px;
    letter-spacing: -5px;
    font-style: italic;
    font-weight: 700;
    text-align: right;
    display: block;
    left: -25px;
    position: absolute;
    top: -13px;
    right: 0;
    margin: 0 0 0 auto;
    width: 640px;
    line-height: 0.9;
    opacity: 0.5;
    @include sp {
      font-size: 65px;
      left: 0;
      top: 74px;
      text-align: center;
      width: 100%;
    }
    &-sub {
      top: 109px;
      @include sp {
        top: 130px;
      }
    }
  }
}


